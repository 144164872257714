<template>
  <el-card
    class="text-left"
    shadow="never">
    <el-button
      v-for="(item, index) in buttonList" :key="index"
      :type="item.active"
      @click="handleRoute(item.route)">
      {{ item.name }}
    </el-button>
  </el-card>
</template>

<script>
export default {
  data () {
    return {
      buttonList: [
        {
          name: '首頁・累计服务人数',
          route: '/admin/market/setting/userCount',
          active: ''
        },
        {
          name: '首頁・最新开奖结果',
          route: '/admin/market/setting/issueResult',
          active: ''
        },
        {
          name: '首頁・热门彩种',
          route: '/admin/market/setting/lotteryHot',
          active: ''
        },
        {
          name: '彩票资讯・奖号预测',
          route: '/admin/market/setting/lotteryPredict',
          active: ''
        }
      ]
    }
  },
  created () {
    this.buttonList.forEach(key => {
      if (location.href.includes(key.route)) {
        key.active = 'warning'
      }
    })
  },
  methods: {
    handleRoute (route) {
      if (!location.href.includes(route)) {
        this.$router.push(route)
      }
      this.buttonList.forEach(key => {
        if (key.route === route) {
          key.active = 'warning'
        } else {
          key.active = ''
        }
      })
    }
  }
}
</script>
