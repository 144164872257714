<template>
  <div>
    <Head />
    <router-view />
  </div>
</template>

<script>
import Head from './Head'

export default {
  components: {
    Head
  }
}
</script>
